import { Link } from 'gatsby'
import React from 'react'

import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import PageWrapper from 'components/layout/page-wrapper'
import useRellax from 'js/utils/hooks/useRellax'

import 'static/legal-inquiries/scss/styles.scss'

function LegalInquiries({ location }) {
  useRellax({ className: 'legal-inquiries-rellax', center: true })

  return (
    <PageWrapper className="x-page-le" headerProps={{ location, trackerPage: 'legal-inquiries' }}>
      <header className="x-header">
        <div className="x-header__bg-container legal-inquiries-rellax" data-rellax-speed={-3}>
          <div />
        </div>
        <div className="x-header__content">
          <div className="x-header__txt-container x__width">
            <Heading page="legal-inquiries" />
          </div>
        </div>
      </header>

      <section className="x-page-le-content">
        <div className="x__width">
          <p className="x-page-le-content__desc">
            From time to time, Exodus Movement, Inc., a Delaware corporation (“Exodus”), receives
            requests from regulatory agencies and law enforcement located around the world. This
            page answers a few of the most common questions we receive from government authorities
            and explains how Exodus processes these requests for information from law enforcement.
          </p>

          <div className="x-page-le-faq">
            <ul className="x-page-le-faq__list">
              <li>
                <h2>
                  <span className="x-page-le-faq__numbering">1</span>
                  Why does Exodus, because of its non-custodial software, generally not have
                  identifying information about its users?
                </h2>
                <p>
                  Exodus is a software company that has developed an unhosted and non-custodial
                  wallet software platform for multiple types of virtual currency (the “Exodus
                  Platform”). The Exodus Platform provides an easy-to-use software interface that
                  permits users to manage virtual currency on their desktop computers, mobile
                  phones, and their Trezor hardware wallets (for added security). The software
                  provided by Exodus permits users of the software to hold, send and receive virtual
                  currency, with each user having total independent control over the private keys of
                  their virtual currency.
                </p>
                <p>
                  The Exodus Platform software is a free download that is used without opening an
                  account with Exodus and without providing any identifying personal information to
                  Exodus. The private keys for the virtual currency are held by the user and are
                  encrypted locally on the user’s device.
                </p>
                <p>
                  Exodus does not have accounts, custody or control over a user’s virtual currency.
                  Consequently, unless a user contacts Exodus and provides it with such information,
                  Exodus does not have any information about a user’s name, email address, address,
                  or national ID number.
                </p>
              </li>
              <li>
                <h2>
                  <span className="x-page-le-faq__numbering">2</span>
                  Can Exodus freeze a user’s wallet?
                </h2>
                <p>
                  No. Exodus cannot freeze or take possession of virtual currency on law
                  enforcement’s behalf or send virtual currency from the user’s wallet to another
                  party. The private keys for the virtual currency are held by the user and
                  encrypted locally on the user’s device.
                </p>
              </li>
              <li>
                <h2>
                  <span className="x-page-le-faq__numbering">3</span>
                  Does Exodus respond to law enforcement information requests?
                </h2>
                <p>
                  Yes. Exodus will respond to valid law enforcement agency requests. Exodus reviews
                  each law enforcement request to determine that it has valid legal basis and that
                  any response is tailored to provide the data requested. Exodus also reserves the
                  right to make disclosures to authorities in order to protect itself and users.
                </p>
                <p>
                  Law enforcement requests should be sent to{' '}
                  <a href="https://app.kodexglobal.com/exodus/signin" target="_blank">
                    https://app.kodexglobal.com/exodus/signin
                  </a>
                  . If you have not used Kodex before, sign up here first:
                  <a href="https://app.kodexglobal.com/exodus/signup" target="_blank">
                    https://app.kodexglobal.com/exodus/signup
                  </a>
                  . Please note, Exodus will only respond to requests sent from email addresses from
                  a government domain.
                </p>
              </li>
              <li>
                <h2>
                  <span className="x-page-le-faq__numbering">4</span>
                  What information should be included in the law enforcement information request?
                </h2>
                <p>
                  To help us respond to your request as quickly as possible, please include the
                  following information:
                  <ol>
                    <li>
                      Name and contact information of requesting officer (email, phone number, and
                      mailing address).
                    </li>
                    <li>Name of the government authority issuing the request.</li>
                    <li>
                      A copy of the subpoena, warrant, court order, investigation letter, or valid
                      information request.
                    </li>
                    <li>
                      Any available information the requesting law enforcement agency is willing to
                      share that may expedite Exodus’s search for any responsive records.
                    </li>
                    <li>
                      A reasonable deadline for the requested information. If your request is
                      urgent, please include a brief explanation for the emergency.
                    </li>
                    <li>A CSV or Excel document containing any related transaction information.</li>
                  </ol>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="x-page-le-footer">
          <div className="x__width">
            <div className="x-page-le-footer__container">
              <span className="x-page-le-footer__title">
                If you have more questions, please contact us.
              </span>
              <Link to="/contact/" className="x__button x__button--shadow-light">
                <span className="x__button-align">
                  <span className="x__button-align-text">Contact Us</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  )
}

export default LegalInquiries

export function Head() {
  return <PageHead page="legal-inquiries" />
}
